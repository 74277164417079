<style>
  .settings-collapse {
    margin-bottom: 1rem;
  }
  .hard-border {
    border-radius: 0 !important;
  }
</style>
<script>
import {  VclCode } from 'vue-content-loading';
import { HalfCircleSpinner } from 'epic-spinners';

import CopyableText from "@/components/CopyableText";
import {get_acsrf_token} from "@/methods";
import {mapGetters} from "vuex";

export default {
  props: ['banlist_id', 'options'],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CopyableText,
    VclCode,
    // eslint-disable-next-line vue/no-unused-components
    HalfCircleSpinner,
	  // eslint-disable-next-line vue/no-unused-components
	  BanListProfile: () => import('@/components/widgets/BanListProfile')
  },
  computed: {
    ...mapGetters(['getDTLocale'])
  },
  methods: {
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    async getData() {
	    try {
		    let url = new URL(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/profile`);

		    let response = await fetch(url, {
			    method: 'GET',
			    credentials: 'include'
		    });

		    if(response.ok) {
			    let data = await response.json();
			    this.banlist = data.banlist;
					this.public_listing = data.public_listing;
					this.banlistCategories = [];
			    data.categories.forEach((category) => {
						this.banlistCategories.push({
							value: category,
							text: this.$t(`banmanager.view.profile.category.options.${category}`)
						})
			    });
					this.$nextTick(() => {
						this.configurationChanged = false;
						this.ready = true;
					});
		    } else {
			    if (response.status === 429) this.$toast.warning(this.$t('error.server.ratelimit.title'));
			    throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
		    }

	    } catch (error) {
		    this.error = true;
		    console.log(`[ERROR] ${error}`);
	    }
    },
	  async updateListingStatus() {
		  this.inProgress = true;
		  try {
			  let acsrf_token = await get_acsrf_token();
			  let payload = {
				  acsrf_token: acsrf_token,
				  listed: this.public_listing.listed
			  };
			  let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/listing-state`, {
				  method: 'POST',
				  body: JSON.stringify(payload),
				  credentials: 'include'
			  });
			  if(response.ok) {
				  this.$swal({
					  icon: 'success',
					  title: this.$t('banmanager.view.profile.update.success')
				  });
				  this.displayPreview = false;
				  await this.getData();
				  this.$nextTick(() => {
					  this.displayPreview = true;
				  })
			  } else {
				  if(response.status === 403) {
					  this.$swal({
						  icon: 'error',
						  text: this.$t('error.permissions.message'),
						  title: this.$t('error.permissions.title')
					  });
				  } else if(response.status === 429) {
					  this.$swal({
						  icon: 'warning',
						  text: this.$t('error.server.ratelimit.action'),
						  title: this.$t('error.server.ratelimit.title')
					  });
					} else if(response.status === 400 && response.error === 'required-fields') {
					  this.$swal({
						  icon: 'warning',
						  title: this.$t('banmanager.view.profile.update.error.required'),
					  });
				  } else
					  throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
			  }
		  } catch (error) {
			  console.log(`[ERROR] ${error}`);
			  this.$swal({
				  icon: 'error',
				  text: this.$t('error.server.generic.message')
			  });
		  }
		  this.inProgress = false;
	  },
	  async updateProfileState() {
		  this.inProgress = true;
		  try {
			  let acsrf_token = await get_acsrf_token();
			  let payload = {
				  acsrf_token: acsrf_token,
				  activated: this.banlist.profile.activated
			  };
			  let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/profile-state`, {
				  method: 'POST',
				  body: JSON.stringify(payload),
				  credentials: 'include'
			  });
			  if(response.ok) {
				  this.$swal({
					  icon: 'success',
					  title: this.$t('banmanager.view.profile.update.success')
				  });
				  this.displayPreview = false;
				  await this.getData();
				  this.$nextTick(() => {
					  this.displayPreview = true;
				  })
			  } else {
				  if(response.status === 403) {
					  this.$swal({
						  icon: 'error',
						  text: this.$t('error.permissions.message'),
						  title: this.$t('error.permissions.title')
					  });
				  } else if(response.status === 429) {
					  this.$swal({
						  icon: 'warning',
						  text: this.$t('error.server.ratelimit.action'),
						  title: this.$t('error.server.ratelimit.title')
					  });
				  } else if(response.status === 400 && response.error === 'required-fields') {
					  this.$swal({
						  icon: 'warning',
						  title: this.$t('banmanager.view.profile.update.error.required'),
					  });
				  } else
					  throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
			  }
		  } catch (error) {
			  console.log(`[ERROR] ${error}`);
			  this.$swal({
				  icon: 'error',
				  text: this.$t('error.server.generic.message')
			  });
		  }
		  this.inProgress = false;
	  },
    async updateProfile() {
      this.inProgress = true;
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
	        profile: this.banlist.profile
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/profile`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.$swal({
            icon: 'success',
            title: this.$t('banmanager.view.profile.update.success')
          });
					this.displayPreview = false;
          await this.getData();
					this.$nextTick(() => {
						this.displayPreview = true;
 					})
        } else {
          if(response.status === 403) {
            this.$swal({
              icon: 'error',
              text: this.$t('error.permissions.message'),
              title: this.$t('error.permissions.title')
            });
          } else if(response.status === 429) {
            this.$swal({
              icon: 'warning',
              text: this.$t('error.server.ratelimit.action'),
              title: this.$t('error.server.ratelimit.title')
            });
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
      this.inProgress = false;
    },
	  bannerPrompt() {
		  document.getElementById('file').click();
	  },
	  async uploadBanner(event) {
		  let toast = this.$toast;

		  let files = event.target.files;
		  if(files.length === 0) return;
		  let file = files[0];
		  this.$refs.file.value = null;

		  try {
			  let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/account/avatar-upload`, {
				  method: 'GET',
				  credentials: 'include'
			  });
			  let data;
			  if(!response.ok) {
				  if(response.status === 400) {
					  data = await response.json();
					  if(data.error === 'exhausted') {
						  this.$swal({
							  icon: 'warning',
							  text: this.$t('error.gryphon.quota.message'),
							  title: this.$t('error.gryphon.quota.title')
						  });
						  throw new Error('gryphon blocked');
					  }
				  } else {
					  this.$swal({
						  icon: 'error',
						  text: this.$t('banmanager.view.profile.banner.error.upload')
					  });
					  return
				  }
			  } else {
				  data = await response.json();
				  file.resource_id = data.resource_id;
				  if(data.storage_warning) {
					  let result = await this.$swal({
						  icon: 'warning',
						  text: this.$t('error.gryphon.quota_warning.message'),
						  title: this.$t('error.gryphon.quota_warning.title'),
						  showCancelButton: true,
						  confirmButtonText: this.$t('error.gryphon.quota_warning.confirm'),
						  confirmButtonColor: '#f46a6a',
						  cancelButtonColor: '#c3cbe4',
					  });
					  if(!result.isConfirmed) throw new Error('cancelled');
				  }
			  }

			  let upload_url = data.upload_url;
			  let upload_token = data.upload_token;

			  let payload, acsrf_token;
			  payload = new FormData();
			  payload.append('file', file);
			  payload.append('upload_token', upload_token);

			  response = await fetch(data.upload_url, {
				  method: 'POST',
				  body: payload,
				  credentials: 'include'
			  });
			  if(!response.ok) throw new Error('upload failed');
			  data = await response.json();

				this.banlist.profile.banner_url = data.resource.url;
				this.$refs.previewWidget.bannerUpdate(this.banlist.profile.banner_url);

		  } catch(e) {
			  console.log(`[ERROR] ${e}`);
			  this.$swal({
				  icon: 'error',
				  text: this.$t('banmanager.view.profile.banner.error.upload')
			  });
		  }
	  }
  },
  mounted() {
    this.getData();
  },
	watch: {
		banlist: {
			deep: true,
			handler() {
				if(!this.ready) return;
				if(this.configurationChanged === null) {
					this.configurationChanged = false;
				} else this.configurationChanged = true;
			}
		}
	},
  data() {
    return {
      ready: false,
      error: false,
	    displayPreview: true,
      inProgress: false,
	    configurationChanged: false,
      banlist: {},
	    public_listing: {},
	    transmission: {
				activated: false,
		    listed: false
	    }
    }
  }
};
</script>

<template>
  <div>
    <template v-if="ready && banlist">
	    <!--
	    <div class="row">
        <div class="col">
          <h3 class="text-center">
            {{ $t('banmanager.view.profile.title') }}
          </h3>
        </div>
      </div>
      -->
	    <div class="row">
		    <div class="col-12">
			    <span class="h4 text-uppercase m-0 p-0">{{ $t('banmanager.view.profile.public.title') }}</span>
			    <div class="row justify-content-center">
				    <div class="col">
					    <div class="d-flex flex-row">
						    <div class="card m-4">
							    <div class="card-body">
								    <div class="d-flex align-items-center mb-3">
									    <div class="avatar-xs mr-3">
                          <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                            <i class="fad fa-file-certificate"></i>
                          </span>
									    </div>
									    <h4 class="mb-0">
										    {{ $t('banmanager.view.profile.perks.public.title') }}
									    </h4>
								    </div>
								    <div class="mt-2">
                        <span class="text-muted">
                          {{ $t('banmanager.view.profile.perks.public.description') }}
                        </span>
								    </div>
							    </div>
						    </div>
						    <div class="card m-4">
							    <div class="card-body">
								    <div class="d-flex align-items-center mb-3">
									    <div class="avatar-xs mr-3">
                          <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                            <i class="fad fa-chart-network"></i>
                          </span>
									    </div>
									    <h4 class="mb-0">
										    {{ $t('banmanager.view.profile.perks.profiles.title') }}
									    </h4>
								    </div>
								    <div class="mt-2">
                        <span class="text-muted">
                          {{ $t('banmanager.view.profile.perks.profiles.description') }}
                        </span>
								    </div>
							    </div>
						    </div>
						    <div class="card m-4">
							    <div class="card-body">
								    <div class="d-flex align-items-center mb-3">
									    <div class="avatar-xs mr-3">
                          <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                            <i class="fad fa-chart-line"></i>
                          </span>
									    </div>
									    <h4 class="mb-0">
										    {{ $t('banmanager.view.profile.perks.recognition.title') }}
									    </h4>
								    </div>
								    <div class="mt-2">
                        <span class="text-muted">
                          {{ $t('banmanager.view.profile.perks.recognition.description') }}
                        </span>
								    </div>
							    </div>
						    </div>
						    <div class="card m-4">
							    <div class="card-body">
								    <div class="d-flex align-items-center mb-3">
									    <div class="avatar-xs mr-3">
                          <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                            <i class="fad fa-sparkles"></i>
                          </span>
									    </div>
									    <h4 class="mb-0">
										    {{ $t('banmanager.view.profile.perks.soon.title') }}
									    </h4>
								    </div>
								    <div class="mt-2">
                        <span class="text-muted">
                          {{ $t('banmanager.view.profile.perks.soon.description') }}
                        </span>
								    </div>
							    </div>
						    </div>
					    </div>
				    </div>
			    </div>
		    </div>
	    </div>
	    <hr>
	    <template v-if="public_listing.verified">
		    <div class="row">
			    <div class="col-3"></div>
			    <div class="col-6">
				    <div class="card border border-success">
					    <div class="card-body">
						    <h5 class="p-0 m-0">
							    <i class="fad fa-sparkles"/>
							    {{ $t('banmanager.view.profile.listing.verified.title') }}
						    </h5>
						    <hr>
						    {{ $t('banmanager.view.profile.listing.verified.description') }}
					    </div>
				    </div>
			    </div>
		    </div>
	    </template>
	    <template v-else-if="!public_listing.available">
		    <div class="row">
			    <div class="col-lg-4 col-sm-12">
				    <div class="card mini-stats-wid noshadow">
					    <div class="card-body">
						    <div class="media">
							    <div class="media-body">
								    <h6 class="text-muted fw-medium mb-2 text-uppercase">
									    {{ $t('banmanager.view.profile.public.profile.title') }}
									    <div class="small text-muted font-size-12">
										    {{ $t('banmanager.view.profile.public.profile.description') }}
									    </div>
								    </h6>
								    <h4 class="mb-0">
										<span class="badge badge-success" v-if="banlist.profile.activated">
											<i class="fad fa-check-double"/>
											{{ $t('banmanager.view.profile.public.profile.qualified') }}
										</span>
									    <span class="badge badge-danger" v-else>
											<i class="fad fa-times"/>
											{{ $t('banmanager.view.profile.public.profile.unqualified') }}
										</span>
								    </h4>
							    </div>
							    <div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-warning">
                  <span class="avatar-title">
                    <i class="font-size-24 fas fa-check" v-if="banlist.profile.activated"></i>
	                  <i class="font-size-24 fas fa-times" v-else></i>
                  </span>
							    </div>
						    </div>
					    </div>
				    </div>
			    </div>
			    <div class="col-lg-4 col-sm-12">
				    <div class="card mini-stats-wid noshadow">
					    <div class="card-body">
						    <div class="media">
							    <div class="media-body">
								    <h6 class="text-muted fw-medium mb-2 text-uppercase">
									    {{ $t('banmanager.view.profile.public.conduct.title') }}
									    <div class="small text-muted font-size-12">
										    {{ $t('banmanager.view.profile.public.conduct.description') }}
									    </div>
								    </h6>
								    <h4 class="mb-0">
										<span class="badge badge-primary">
											<i class="fad fa-info-circle"/>
											{{ $t('banmanager.view.profile.public.conduct.disclaimer') }}
										</span>
								    <small class="font-size-12">
									    <a href="https://help.cftools.com/en/cf-tools-cloud/public-listing" target="_blank">
										    Code of Conduct <i class="fal fa-external-link"/>
									    </a>
								    </small>
								    </h4>
							    </div>
							    <div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-warning">
                  <span class="avatar-title">
                    <i class="font-size-24 fad fa-hourglass"></i>
                  </span>
							    </div>
						    </div>
					    </div>
				    </div>
			    </div>
			    <div class="col-lg-4 col-sm-12">
				    <div class="card mini-stats-wid noshadow">
					    <div class="card-body">
						    <div class="media">
							    <div class="media-body">
								    <h6 class="text-muted fw-medium mb-2 text-uppercase">
									    {{ $t('banmanager.view.profile.public.reach.title') }}
									    <div class="small text-muted font-size-12">
										    {{ $t('banmanager.view.profile.public.reach.description') }}
									    </div>
								    </h6>
								    <h4 class="mb-0">
										<span class="badge badge-primary">
											<i class="fad fa-info-circle"/>
											{{ $t('banmanager.view.profile.public.reach.disclaimer') }}
										</span>

								    </h4>
							    </div>
							    <div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-warning">
                  <span class="avatar-title">
                    <i class="font-size-24 fad fa-hourglass"></i>
                  </span>
							    </div>
						    </div>
					    </div>
				    </div>
			    </div>
		    </div>
	    </template>
	    <template v-else>
		    <div class="row">
			    <div class="col-3"></div>
			    <div class="col-6">
				    <div class="card border border-success">
					    <div class="card-body">
						    <h5 class="p-0 m-0">
							    <i class="fad fa-sparkles"/>
							    {{ $t('banmanager.view.profile.listing.available.title') }}
						    </h5>
						    <hr>
						    <div>
							    {{ $t('banmanager.view.profile.listing.available.description') }}
						    </div>
						    <div class="row mt-4 justify-content-center">
							    <div class="w-25">
								    <div>
									    <h4>
										    {{ $t('banmanager.view.profile.listing.available.setting_title') }}
									    </h4>
									    <span>
                      {{ $t('banmanager.view.profile.listing.available.setting_description') }}
                    </span>
								    </div>
							    </div>
							    <div class="w-25">
								    <toggle-button ref="banlistListed" v-model="public_listing.listed" @change="updateListingStatus()"
								                   :disabled="transmission.listed"
								                   :sync="true"
								                   :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
								                   :height="40"
								                   :width="80"
								                   :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
								                   style="font-size: 13px !important;"
								                   class="m-0 mt-1 mb-1"
								    />
								    <div class="d-inline ml-3" v-if="$refs.banlistListed && $refs.banlistListed.disabled">
									    <i class="far fa-circle-notch fa-spin text-white font-size-16"></i>
								    </div>
							    </div>
						    </div>
					    </div>
				    </div>
			    </div>
		    </div>
	    </template>

	    <div class="row mt-4 align-items-middle">
		    <div class="col-1"/>
		    <div class="col-6">
			    <!-- Category -->
			    <div class="row">
				    <div class="col">
					    <div class="mb-1">
						    <h4 class="m-0 p-0">
							    {{ $t('banmanager.view.profile.category.title') }}
							    <span class="badge badge-primary h6">
								    <i class="fad fa-info-circle"/>
								    {{ $t('banmanager.view.profile.category.disclaimer') }}
							    </span>
						    </h4>
						    <span>
                  {{ $t('banmanager.view.profile.category.description') }}
                </span>
					    </div>
					    <b-select
							    class="form-control"
							    v-model="banlist.profile.category"
							    :options="banlistCategories"
					    />
				    </div>
			    </div>
			    <!-- --- -->

			    <!-- Contact-E-Mail -->
			    <div class="row mt-3">
				    <div class="col">
					    <div class="mb-1">
						    <h4 class="m-0 p-0">
							    {{ $t('banmanager.view.profile.contact_email.title') }}
							    <span class="badge badge-primary h6">
								    <i class="fad fa-info-circle"/>
								    {{ $t('banmanager.view.profile.contact_email.disclaimer') }}
							    </span>
						    </h4>
						    <span>
                  {{ $t('banmanager.view.profile.contact_email.description') }}
                </span>
					    </div>

					    <b-input
							    v-model="banlist.profile.contact.email"
							    :placeholder="$t('banmanager.view.profile.contact_email.placeholder')"
							    type="text"
							    class="form-control"
					    />
				    </div>
			    </div>
			    <!-- --- -->

			    <!-- Description -->
			    <div class="row mt-3">
				    <div class="col">
					    <div class="mb-1">
						    <h4 class="m-0 p-0">
							    {{ $t('banmanager.view.profile.description.title') }}
							    <span class="badge badge-primary h6">
								    <i class="fad fa-info-circle"/>
								    {{ $t('banmanager.view.profile.description.disclaimer') }}
							    </span>
						    </h4>
						    <span>
                  {{ $t('banmanager.view.profile.description.description') }}
                </span>
					    </div>

					    <b-textarea
							    v-model="banlist.profile.description"
							    :placeholder="$t('banmanager.view.profile.description.placeholder')"
							    type="text"
							    class="form-control"
					    />
				    </div>
			    </div>
			    <!-- --- -->

			    <!-- Contact-Discord -->
			    <div class="row mt-3">
				    <div class="col">
					    <div class="mb-1">
						    <h4 class="m-0 p-0">
							    {{ $t('banmanager.view.profile.contact_discord.title') }}
						    </h4>
					    </div>

					    <b-input
							    v-model="banlist.profile.contact.discord"
							    :placeholder="$t('banmanager.view.profile.contact_discord.placeholder')"
							    type="text"
							    class="form-control"
					    />
				    </div>
			    </div>
			    <!-- --- -->

			    <!-- Contact-Website -->
			    <div class="row mt-3">
				    <div class="col">
					    <div class="mb-1">
						    <h4 class="m-0 p-0">
							    {{ $t('banmanager.view.profile.contact_website.title') }}
						    </h4>
					    </div>

					    <b-input
							    v-model="banlist.profile.contact.website"
							    :placeholder="$t('banmanager.view.profile.contact_website.placeholder')"
							    type="text"
							    class="form-control"
					    />
				    </div>
			    </div>
			    <!-- --- -->

			    <!-- Contact-Teamspeak -->
			    <div class="row mt-3">
				    <div class="col">
					    <div class="mb-1">
						    <h4 class="m-0 p-0">
							    {{ $t('banmanager.view.profile.contact_teamspeak.title') }}
						    </h4>
					    </div>

					    <b-input
							    v-model="banlist.profile.contact.teamspeak"
							    :placeholder="$t('banmanager.view.profile.contact_teamspeak.placeholder')"
							    type="text"
							    class="form-control"
					    />
				    </div>
			    </div>
			    <!-- --- -->
		    </div>

		    <div class="col-4">
			    <div class="row">
				    <div class="col">
					    <button class="btn btn-block hard-border save-btn"
		            v-on:click="updateProfile()"
		            :disabled="inProgress"
		            :class="{
                  'disabled': inProgress,
                  'btn-success': configurationChanged === true,
                  'btn-primary': configurationChanged === false
                }"
					    >
						    <half-circle-spinner
								    v-if="inProgress"
								    :animation-duration="1200"
								    :size="16"
								    class="align-middle d-inline-block"
						    />
						    <template v-if="!inProgress">
							    {{ $t('banmanager.view.profile.update.button') }}
						    </template>
					    </button>
				    </div>
			    </div>

			    <div class="p-2">
				    <span class="h6 text-uppercase m-0 p-0">{{ $t('banmanager.view.profile.preview.title') }}</span>
				    <input type="file" accept="image/*" v-on:change="uploadBanner($event)" id="file" style="display: none;" ref="file">
				    <div class="border" style="margin-left: auto; margin-right: auto; max-width: 318px !important;">
					    <BanListProfile ref="previewWidget" :banlist_id="banlist_id" :preview="true" v-if="displayPreview"/>
					    <button class="btn btn-block btn-info hard-border save-btn" v-on:click="bannerPrompt()">
						    <i class="fad fa-cloud-upload" />
						    {{ $t('banmanager.view.profile.banner.button') }}
					    </button>
				    </div>



				    <div class="mt-4 text-center">
					    <h4 class="p-0 m-0">
						    {{ $t('banmanager.view.profile.activated.button') }}
					    </h4>
					    <toggle-button ref="profileActivate" v-model="banlist.profile.activated" @change="updateProfileState()"
                 :disabled="transmission.activated"
                 :sync="true"
                 :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
                 :height="40"
                 :width="80"
                 :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
                 style="font-size: 13px !important;"
                 class="m-0 mt-1 mb-1"
					    />
					    <div class="d-inline ml-3" v-if="$refs.profileActivate && $refs.profileActivate.disabled">
						    <i class="far fa-circle-notch fa-spin text-white font-size-16"></i>
					    </div>
					    <h6 class="p-0 m-0 text-uppercase mt-1" v-if="banlist.profile.activated">
						    <i class="fad fa-sparkles text-muted"/>
						    {{ $t('banmanager.view.profile.activated.disclaimer') }}
					    </h6>
				    </div>
			    </div>
		    </div>
	    </div>
	    <div class="row mt-4 mb-4"/>
    </template>
    <template v-else-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-lg-11">
          <!-- Content row one -->
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
